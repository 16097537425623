import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Flex, Box } from '@rebass/grid'
import styled from 'styled-components'
import useWindowScrollPosition from '@rehooks/window-scroll-position'
import { langs } from '../langs'
import { D, M, DesktopFlex, MobileFlex } from './breakpoints'
import MenuButton from './navbarMenuButton'
import { OutlineButton } from './buttons'
import MenuUseCaseImg from '../static/img/use_cases/menuUseCase.svg'

const Link = styled.a`
  font-weight: 400;
  font-size: 13px;
  line-height: normal;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  color: ${props => props.color};
  margin-right: 30px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`
const UseCases = styled.a`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 23px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

const MenuUseCase = props => {
  let topImage
  let topCases
  if (!props.page) {
    topImage = 37
    topCases = 54
  } else {
    topImage = 43
    topCases = 57
    if (props.page == 'Home') {
      if (props.style) {
        topImage = 35
        topCases = 48
      } else {
        topImage = 60
        topCases = 76
      }
    }
  }

  return (
    <>
      <MenuUseCaseImg
        style={{ position: 'absolute', top: topImage, marginLeft: -95 }}
      />
      <Flex
        flexDirection="column"
        style={{ position: 'absolute', top: topCases, marginLeft: -43 }}
        ml={55}
      >
        <UseCases href={`${langs[props.lang]}/customer-service/`}>
          CUSTOMER SERVICE
        </UseCases>
        <UseCases href="/sales/">SALES</UseCases>
        <UseCases href="/marketing/">MARKETING</UseCases>
      </Flex>
    </>
  )
}

const menu = [
  {
    label: { en_US: 'WHATSAPP', en_GB: 'WHATSAPP', es_ES: 'WHATSAPP' },
    link: {
      en_US: '/whatsapp-business-api/',
      en_GB: '/uk/whatsapp-business-api/',
      es_ES: '/es/whatsapp-business-api/',
    },
  },
  {
    label: { en_US: 'USES', en_GB: 'USES', es_ES: 'USES' },
    link: {
      en_US: '/useCases/',
      en_GB: '/uk/useCases/',
      es_ES: '/es/useCases/',
    },
  },
  {
    label: { en_US: 'PRICING', en_GB: 'PRICING', es_ES: 'PLANES' },
    link: {
      en_US: '/pricing/',
      en_GB: '/uk/pricing/',
      es_ES: '/es/pricing/',
    },
  },
  {
    label: {
      en_US: 'DOCUMENTATION',
      en_GB: 'DOCUMENTATION',
      es_ES: 'DOCUMENTACIÓN',
    },
    link: {
      en_US: 'https://docs.botonic.io/',
      en_GB: 'https://docs.botonic.io/',
      es_ES: 'https://docs.botonic.io/',
    },
  },
  {
    label: { en_US: 'CONTACT', en_GB: 'CONTACT', es_ES: 'CONTACTO' },
    link: {
      en_US: '/contact/',
      en_GB: '/uk/contact/',
      es_ES: '/es/contact/',
    },
  },
]

const Navbar = props => {
  const { blackLogo, whiteLogo } = useStaticQuery(
    graphql`
      query {
        blackLogo: file(relativePath: { eq: "blackLogo.png" }) {
          childImageSharp {
            fixed(width: 100, height: 30) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        whiteLogo: file(relativePath: { eq: "whiteLogo.png" }) {
          childImageSharp {
            fixed(width: 138, height: 41) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  )
  const [menuIsOpened, setMenuIsOpened] = useState(false)
  let position = useWindowScrollPosition()
  let lang = props.pageContext.lang || 'en_US'
  let path = {
    language:
      props.pageContext.lang == 'es_ES' ? '/es' : lang == 'en_GB' ? '/uk' : '/',
  }
  let style =
    props.yThreshold && position.y > props.yThreshold
      ? props.styleThreshold || {}
      : props.style || {}
  let color = props.fontColor || (props.theme === 'dark' ? '#fff' : '#000')
  let primaryColor = props.theme === 'dark' ? '#fff' : '#FF2B5E'
  let hoverColor = props.theme === 'dark' ? '#FF2B5E' : '#fff'
  let bgColor = props.theme === 'dark' ? '#000' : '#fff'
  let widthLogo = style.background === '#262626' ? '111px' : 138
  let heightLogo = style.background === '#262626' ? '32px' : 41
  let logo =
    props.theme === 'dark' ? (
      <Img
        style={{ width: widthLogo, height: heightLogo }}
        fixed={whiteLogo.childImageSharp.fixed}
      />
    ) : (
      <Img fixed={blackLogo.childImageSharp.fixed} />
    )
  let L = props => <Link color={color} {...props} />
  return (
    <Flex
      py={[12, 12, 20]}
      px={[18, 18, 0]}
      alignItems="center"
      justifyContent="center"
      {...props}
      style={{
        zIndex: 3,
        background: bgColor,
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        width: '100%',
        borderBottom: '1px solid #E5E5E5',
        transition: '0.3s',
        ...style,
      }}
    >
      <Flex
        flex="1 1 auto"
        alignItems="center"
        css={{ width: '100%', maxWidth: 1110 }}
      >
        <Flex flex="1 1 auto" alignItems="center" justifyContent="flex-start">
          <a href={path.language} style={{ height: 30, paddingTop: 1 }}>
            {logo}
            {/*<Img style={{ ...props.imageStyle }} fixed={image} />*/}
          </a>
        </Flex>
        <DesktopFlex alignItems="center">
          {menu.map((m, i) => {
            if (m.label[lang] == 'USES') {
              return (
                <L
                  key={i}
                  onMouseOver={() => setMenuIsOpened(true)}
                  onMouseLeave={() => setMenuIsOpened(false)}
                >
                  {m.label[lang]}
                  {menuIsOpened && (
                    <MenuUseCase
                      style={style.paddingTop}
                      page={props.pageContext.template}
                      lang={lang}
                    />
                  )}
                </L>
              )
            } else {
              return (
                <L key={i} href={m.link[lang]}>
                  {m.label[lang]}
                </L>
              )
            }
          })}
          <OutlineButton
            style={{ minHeight: '32px', borderRadius: 5 }}
            hoverBackground="transparent"
            color={primaryColor}
            hoverColor={primaryColor}
            hoverBackground={hoverColor}
            href="https://app.hubtype.com"
          >
            Login
          </OutlineButton>
        </DesktopFlex>

        <MobileFlex>
          <MenuButton menu={menu} lang={lang} color={color} />
        </MobileFlex>
      </Flex>
    </Flex>
  )
}

Navbar.defaultProps = {
  theme: 'light',
  yThreshold: 0,
}

export default Navbar
