import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuIcon from '@material-ui/icons/Menu'
import { Flex, Box } from '@rebass/grid'
import NavbarLogo from '../static/img/navbarLogo.svg'
import Line from '../static/img/home/menuNavbarLine.svg'
import Cross from '../static/img/icons/cross.svg'
import styled from 'styled-components'
import { Button } from '@material-ui/core'

const Title = styled.h1`
  font-family: Heebo;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px; /* identical to box height */
  text-transform: uppercase;
  color: #ffffff;
`

const options = [
  //'HOW IT WORKS',
  //'PRODUCTS',
  //'USES',
  { label: 'PRICING', link: '/pricing/' },
  { label: 'DOCUMENTATION', link: 'https://docs.botonic.io/' },
  { label: 'SCHEDULE A DEMO', link: '/contact/' },
]

const ITEM_HEIGHT = 48

class LongMenu extends React.Component {
  state = {
    anchorEl: null,
    isOpened: false,
    showUseMenu: false,
  }

  handleClick = event => {
    //this.setState({ anchorEl: event.currentTarget })
    this.setState({
      ...this.state,
      anchorEl: event.currentTarget,
      isOpened: true,
      showUseMenu: false,
    })
  }
  handleUseCaseMenu = () => {
    if (this.state.showUseMenu) {
      this.setState({
        ...this.state,
        showUseMenu: false,
      })
    } else {
      this.setState({
        ...this.state,
        showUseMenu: true,
      })
    }
  }
  render() {
    //let isOpened = false
    //const { anchorEl } = this.state
    //const open = Boolean(anchorEl)

    return (
      <div>
        <IconButton
          aria-label="More"
          aria-owns={'long-menu'}
          aria-haspopup="true"
          onClick={event => this.handleClick(event)}
          style={{ color: this.props.color || 'white' }}
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={this.state.anchorEl}
          open={this.state.isOpened}
          onClose={() => this.handleClose()}
          PaperProps={{
            style: {
              height: '100%',
              width: '100%',
              backgroundColor: '#2B2A2A',
            },
          }}
          style={{ backgroundColor: '#2B2A2A' }}
        >
          <Flex
            style={{ backgroundColor: '#6F16FF' }}
            justifyContent={'space-between'}
          >
            <Box ml={2} mt={2} onClick={() => (window.location = '/')}>
              <NavbarLogo />
            </Box>
            <Box mr={3} mt={2}>
              <Button>
                <Cross
                  onClick={() =>
                    this.setState({ ...this.state, isOpened: false })
                  }
                />
              </Button>
            </Box>
          </Flex>
          <Flex ml={3} mt={4} flexDirection={'column'}>
            {this.props.menu.map((m, i) => {
              if (m.label[this.props.lang] == 'USES') {
                return (
                  <Box key={i} mb={-2}>
                    <Title onClick={() => this.handleUseCaseMenu()}>
                      {m.label[this.props.lang]}
                    </Title>
                    {this.state.showUseMenu && (
                      <Box ml={20}>
                        <a href={'/sales/'}>
                          <Title>SALES</Title>
                        </a>
                        <a href={'/customer-service/'}>
                          <Title>CUSTOMER SERVICE</Title>
                        </a>
                        <a href={'/marketing/'}>
                          <Title>MARKETING</Title>
                        </a>
                      </Box>
                    )}
                  </Box>
                )
              } else {
                return (
                  <Box key={i} mb={-2}>
                    <a href={m.link[this.props.lang]}>
                      <Title>{m.label[this.props.lang]}</Title>
                    </a>
                  </Box>
                )
              }
            })}
            <Line />
          </Flex>
        </Menu>
      </div>
    )
  }
}

export default LongMenu
